import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {LoggingService} from '../logging/logging.service';
import imageCompression from 'browser-image-compression';
import {DetectionService} from '../detection/detection.service';

export class SafeObjectUrl {
	constructor(readonly url: string) {
	}

	get unsafeUrl(): string {
		return this.url;
	}
}

@Injectable({
	providedIn: 'root'
})
export class FormatService {
	public FORMATS = {
		VIDEO: ['.mp4', '.mov', '.webm'],
		IMAGE: ['.png', '.jpg', '.gif', '.jpeg'],
		MIME_TYPES: {
			mp4: 'video/mp4',
			mov: 'video/quicktime',
			png: 'image/png',
			jpg: 'image/jpeg',
			gif: 'image/gif',
			jpeg: 'image/jpeg',
		}
	};

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private log: LoggingService,
		private detectionService: DetectionService
	) {
	}

	convertVideo(sourceVideoFile, targetVideoFormat) {
		return new Promise(async (resolve, reject) => {
			try {

				const convertedVideoDataObj = (await this.videoConvert(sourceVideoFile, targetVideoFormat)) as any;
				this.log.debug('Converted video' + convertedVideoDataObj.name + convertedVideoDataObj.format);

				resolve(convertedVideoDataObj);
			} catch (err) {
				this.log.error('Error in video conversion', err);
				reject(err);
			}
		});
	}

	generateFormatList() {
		let formatList = '';

		for (let i = 0; i < this.FORMATS.IMAGE.length; i++) {
			formatList += this.FORMATS.IMAGE[i] + ',';
		}

		formatList.substring(0, formatList.length - 1);

		return formatList;
	}

	isSupported(url) {
		for (let i = 0; i < this.FORMATS.VIDEO.length; i++) {
			if (url.toLowerCase().includes(this.FORMATS.VIDEO[i])) {
				return true;
			}
		}

		for (let i = 0; i < this.FORMATS.IMAGE.length; i++) {
			if (url.toLowerCase().includes(this.FORMATS.IMAGE[i])) {
				return true;
			}
		}

		return false;
	}

	isVideo(url) {
		for (let i = 0; i < this.FORMATS.VIDEO.length; i++) {
			if (url.toLowerCase().includes(this.FORMATS.VIDEO[i])) {
				return true;
			}
		}

		return false;
	}

	getMimeType(url) {
		const extension = url.substring(url.lastIndexOf('.') + 1).toLowerCase();
		return {
			ext: extension,
			mime: this.FORMATS.MIME_TYPES[extension]
		};
	}

	public generateImageThumbnail(inputBlob) {
		return new Promise(async (resolve, reject) => {

			try {
				const options = {
					maxSizeMB: 0.1,
					maxWidthOrHeight: 750,
					useWebWorker: true
				};

				const compressedFile = await imageCompression(inputBlob, options);

				this.log.debug('Compressed Blob', compressedFile);
				resolve(await imageCompression.getDataUrlFromFile(compressedFile));

			} catch (err) {
				reject(err);
			}
		});
	}

	async getBase64FromUrl(url) {
		const data = await fetch(url, {cache: 'no-store'});
		const blob = await data.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result;
				resolve(base64data);
			};
		});
	}

	public generateVideoMeta(videoFile) {
		const video: HTMLVideoElement = this.document.createElement('video');
		// const source: HTMLSourceElement = this.document.createElement('source');
		// video.appendChild(source);

		const canvas: HTMLCanvasElement = this.document.createElement('canvas');
		const context: CanvasRenderingContext2D = canvas.getContext('2d');
		return new Promise((resolve, reject) => {
			try {

				canvas.addEventListener('error', reject);
				video.addEventListener('error', reject);
				if (this.detectionService.isMobile() && this.detectionService.ios()) {
					video.oncanplay = () => {
						video.currentTime = 0.1;
						video.oncanplay = null;
					};

					video.onseeked = (e) => {
						this.log.debug('video onloadedmetadata triggered');

						this.log.debug('video.videoWidth' + video.videoWidth);
						this.log.debug('video.videoHeight' + video.videoHeight);

						const videoWithExtracted = video.videoWidth ? video.videoWidth : 300;
						const videoHeighExtracted = video.videoHeight ? video.videoHeight : 300;
						canvas.width = videoWithExtracted;
						canvas.height = videoHeighExtracted;

						this.log.debug('video.videoWidth' + videoWithExtracted);
						this.log.debug('video.videoHeight' + videoHeighExtracted);

						context.drawImage(video, 0, 0, videoWithExtracted, videoHeighExtracted);

						canvas.toBlob(async (blob: Blob) => {
							this.log.debug('Hex Image Data Video blob', blob);
							if (!blob) {
								video.pause();
								this.log.debug('Hex Image Data Fallback blob');
								const fallBackImageBlob = await fetch(window.location.protocol + '//' + window.location.host + '/assets/img/placeholder_media_image.png', {cache: 'no-store'}).then(r => r.blob());
								this.log.debug('Hex Image Data Fallback blob', fallBackImageBlob);
								resolve({
									thumb: this.createSafeObjectUrlFromArrayBuffer(await fallBackImageBlob.arrayBuffer()),
									duration: video.duration
								});
							} else {
								video.pause();
								resolve({
									thumb: this.createSafeObjectUrlFromArrayBuffer(await blob.arrayBuffer()),
									duration: video.duration
								});
							}
						}, 'image/jpeg', 0.50);
					};

					video.onloadedmetadata = () => {
					};

					video.src = videoFile;

				} else {
					video.oncanplay = () => {

						this.log.debug('video oncanplay play triggered');

						this.log.debug('video.videoWidth' + video.videoWidth);
						this.log.debug('video.videoHeight' + video.videoHeight);

						const videoWithExtracted = video.videoWidth ? video.videoWidth : 300;
						const videoHeighExtracted = video.videoHeight ? video.videoHeight : 300;
						canvas.width = videoWithExtracted;
						canvas.height = videoHeighExtracted;

						this.log.debug('video.videoWidth' + videoWithExtracted);
						this.log.debug('video.videoHeight' + videoHeighExtracted);

						context.drawImage(video, 0, 0, videoWithExtracted, videoHeighExtracted);

						canvas.toBlob(async (blob: Blob) => {
							this.log.debug('Hex Image Data Video blob', blob);
							if (!blob) {
								video.pause();
								this.log.debug('Hex Image Data Fallback blob');
								const fallBackImageBlob = await fetch(window.location.protocol + '//' + window.location.host + '/assets/img/placeholder_media_image.png', {cache: 'no-store'}).then(r => r.blob());
								this.log.debug('Hex Image Data Fallback blob', fallBackImageBlob);
								resolve({
									thumb: this.createSafeObjectUrlFromArrayBuffer(await fallBackImageBlob.arrayBuffer()),
									duration: video.duration
								});
							} else {
								video.pause();
								resolve({
									thumb: this.createSafeObjectUrlFromArrayBuffer(await blob.arrayBuffer()),
									duration: video.duration
								});
							}
						}, 'image/jpeg', 0.50);
					};

					video.preload = 'auto';
					video.src = videoFile + '#t=0.001';
				}


				this.log.debug('VideoFile Set', videoFile);
				this.log.debug('VideoFile Type', videoFile.type);
				video.load();
				// video.play();

			} catch (error) {
				this.log.error('Error in conversion process');
				reject(error);
			}
		});
	}

	createSafeObjectUrlFromArrayBuffer(contents: ArrayBuffer) {
		return this.createSafeObjectURL(new Blob([new Uint8Array(contents)]));
	}

	createSafeObjectURL(blob: Blob) {
		return new SafeObjectUrl(URL.createObjectURL(blob));
	}

	arrayBufferToBase64(buffer) {
		let binary = '';
		const bytes = new Uint8Array(buffer);
		const len = bytes.byteLength;
		for (let i = 0; i < len; i++) {
			binary += String.fromCharCode(bytes[i]);
		}
		return window.btoa(binary);
	}

	videoConvert(videoFileData, targetFormat) {
		try {
			targetFormat = targetFormat.toLowerCase();
			const reader = new FileReader();
			return new Promise(resolve => {
				reader.onload = (event) => {
					const contentType = 'video/' + targetFormat;
					// @ts-ignore
					const data = event.target.result.split(',');
					const b64Data = data[1];
					const blob = this.getBlobFromBase64Data(b64Data, contentType);
					const blobUrl = this.createSafeObjectURL(blob);

					const convertedVideo = {
						name: 'video' + new Date().getTime(),
						format: targetFormat,
						data: blobUrl
					};
					// console.log("convertedVideo: ", convertedVideo);
					resolve(convertedVideo);
				};
				reader.readAsDataURL(videoFileData);
			});

		} catch (e) {
			console.log('Error occurred while converting : ', e);
		}
	}

	getBlobFromBase64Data(b64Data, contentType, sliceSize = 512) {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array(slice.length);
			for (let i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}

			const byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}

		return new Blob(byteArrays, {type: contentType});
	}
}
