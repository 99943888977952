import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DetectionService} from '../detection/detection.service';

@Injectable({
	providedIn: 'root'
})
export class LoggingService {

	isWeb = false;

	public loggingActive = true;

	constructor(public http: HttpClient,
	            private detectionService: DetectionService,
	) {
		this.isWeb = document.URL.startsWith('http');
		this.debug('LOGGER is web: ' + this.isWeb);
		this.debug('Hello LoggingUtilsServiceProvider Provider');
	}

	public debug(text: string, content = undefined) {

	  if(!this.loggingActive){
	    return;
    }

		if (content === undefined) {
			console.log(text);
		} else {
			if (this.detectionService.isMobile()) {
				console.log(text + content);
			} else {
				console.log(text, content);
			}
		}
	}

	public error(text: string, content = undefined) {

    if(!this.loggingActive){
      return;
    }

		if (content === undefined) {
			console.error(text);
		} else {
			if (this.detectionService.isMobile()) {
				console.error(text + content);
			} else {
				console.error(text, content);
			}
		}
	}

  activateLogging() {
    this.loggingActive = true;
  }

  disableLogging() {
    this.loggingActive = false;
  }

}
