import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class DetectionService {

  constructor(
    private platform: Platform,
  ) {
  }

  public isMobile() {
    return this.android() || this.ios();
  }

  public ios() {
    return this.platform.is('ios');
  }

  public android() {
    return this.platform.is('android');
  }


}
