import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MediaCropperPageRoutingModule } from './media-cropper-routing.module';

import { MediaCropperPage } from './media-cropper.page';
import {AngularCropperjsModule} from 'angular-cropperjs';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        MediaCropperPageRoutingModule,
        AngularCropperjsModule
    ],
  declarations: [MediaCropperPage]
})
export class MediaCropperPageModule {}
