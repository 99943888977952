/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

@Injectable()
export class Config {

	public VERSION = '1.0.0';

	public SERVER = {
		OCEANBEER: {
			server: 'https://mjr0sxb1tc.execute-api.eu-central-1.amazonaws.com/pro',
			socket: 'wss://jhkrk3iox7.execute-api.eu-central-1.amazonaws.com/pro'
		},
		PORSCHE: {
			server: 'https://mbwak0a75m.execute-api.eu-central-1.amazonaws.com/pro',
			socket: 'wss://gvger3n95j.execute-api.eu-central-1.amazonaws.com/pro',
			wing_control: true,
		},
		LORRYDEV: {
			server: 'https://dfplhfyfu6.execute-api.eu-central-1.amazonaws.com/pro',
			socket: 'wss://qqgxbnj355.execute-api.eu-central-1.amazonaws.com/pro'
		},
		MAGO: {
			server: 'https://3f8h8qatda.execute-api.eu-central-1.amazonaws.com/pro',
			socket: 'wss://lveg3m7sp5.execute-api.eu-central-1.amazonaws.com/pro'
		},
		CANADA: {
			server: 'https://qnq43g9pt4.execute-api.eu-central-1.amazonaws.com/pro',
			socket: 'wss://1ray7skkvk.execute-api.eu-central-1.amazonaws.com/pro'
		},
		LOCAL: {
			server: 'http://localhost:3020/pro',
			socket: 'ws://localhost:3001',
		},
	};
}
