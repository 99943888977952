import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import imageCompression from 'browser-image-compression';
import {CropperComponent} from 'angular-cropperjs';
import {LoggingService} from "../../services/logging/logging.service";
import {FormatService} from "../../services/format/format.service";

@Component({
	selector: 'app-media-cropper',
	templateUrl: './media-cropper.page.html',
	styleUrls: ['./media-cropper.page.scss'],
})
export class MediaCropperPage implements OnInit {
	@Input('resolution') resolution;
	@Input('imageUrl') imageUrl;
	@ViewChild('angularCropper', {static: false}) public angularCropper: CropperComponent;
	@ViewChild('imageCropper', {static: false}) public imageCropper;

	private isLoading = false;

	public cropperOptions;

	constructor(
		private modalCtrl: ModalController,
		private loadingCtr: LoadingController,
		private log: LoggingService,
		private formatService: FormatService
	) {
	}

	toBase64(blob: Blob) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64String = reader.result;
				console.log('Base64 String - ', base64String);
				resolve(base64String);
			};
		});
	}

	ngOnInit() {

		this.log.debug('Init MediaCropper with ', {
			resolution: this.resolution,
			imageUrl: this.imageUrl
		});


		this.cropperOptions = {
			viewMode: 0,
			dragMode: 'crop',
			aspectRatio: 1 / 1,
			autoCrop: true,
			movable: true,
			zoomable: true,
			scalable: false,
			autoCropArea: 1,
			zoomOnWheel: false,
			zoomOnTouch: false,
		};
		this.presentLoading();
	}

	close() {
		this.modalCtrl.dismiss();
	}

	async cropImage() {
		const CONTENT_TYPE = 'image/jpeg';

		const croppedImage = this.angularCropper.cropper.getCroppedCanvas(
			{
				imageSmoothingEnabled: true,
				imageSmoothingQuality: 'high',
			}
		).toDataURL(CONTENT_TYPE);

		const imageBlob = this.formatService.getBlobFromBase64Data(croppedImage.split(',')[1], CONTENT_TYPE);

		const compressOptions = {
			maxSizeMB: 0.45,
			useWebWorker: true,
			fileType: CONTENT_TYPE
		};
		const compressedImageFile = await imageCompression(imageBlob as File, compressOptions);
		const compressedImageUrl = await imageCompression.getDataUrlFromFile(compressedImageFile);

		const title = 'Image-' + new Date().getTime();

		fetch(compressedImageUrl, {cache: 'no-store'})
			.then(res => res.blob())
			.then(async (blob) => {
				const thumbNail = await this.formatService.generateImageThumbnail(blob);

				const imageValue = {
					cropped: compressedImageUrl,
					name: title,
					thumb: thumbNail,
				};

				this.log.debug('Return Image', imageValue);

				this.modalCtrl.dismiss(imageValue);
			});
	}

	fullyLoadedImage() {
		this.log.debug('fullyLoadedImage');
		this.dismissLoading();
	}

	async dismissLoading() {
		if (this.isLoading) {
			this.isLoading = false;
			return await this.loadingCtr.dismiss().then(() => this.log.debug('dismissed'));
		}
	}

	async presentLoading() {
		this.isLoading = true;
		return await this.loadingCtr.create({}).then(a => {
			a.present().then(() => {
				this.log.debug('presented');
				if (!this.isLoading) {
					a.dismiss().then(() => this.log.debug('abort presenting'));
				}
			});
		});
	}

	zoom(zoomIn) {
		if (zoomIn) {
			this.angularCropper.cropper.zoom(0.1);
		} else {
			this.angularCropper.cropper.zoom(-0.1);
		}
	}

	rotate(rotateLeft) {
		if (rotateLeft) {
			this.angularCropper.cropper.rotate(-90);
		} else {
			this.angularCropper.cropper.rotate(90);
		}
	}

}
