import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HttpClientModule} from "@angular/common/http";
import {MediaCropperPageModule} from "./pages/media-cropper/media-cropper.module";
import {Config} from "./config/config";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios'
    }),
    AppRoutingModule,
    HttpClientModule,
    MediaCropperPageModule,
  ],
  providers: [{provide: RouteReuseStrategy, useClass: IonicRouteStrategy}, Config],
  bootstrap: [AppComponent],
})
export class AppModule {
}
